
import { mdiRefresh, mdiCalendar } from "@mdi/js"
export default {
    name: "StatisticsDateRangeSelect",
    data: function () {
        return {
            mdiRefresh,
            mdiCalendar,
            menuFrom: false,
            menuTo: false,
            fromDate: null,
            toDate: null,
        }
    },
    emits: ["refresh"],
    methods: {
        refresh() {
            const params = {
                fromDate: this.fromDate,
                toDate: this.toDate,
            }
            this.$emit("refresh", params)
        },
    },
}
