
import StatisticsDateRangeSelect from "@/components/statistics/StatisticsDateRangeSelect"
import VueApexCharts from "vue-apexcharts"

export default {
    name: "OabatDashboardGraph",
    components: {
        VueApexCharts,
        StatisticsDateRangeSelect,
    },
    emits: ["refresh"],
    props: {
        options: {
            type: Object,
            default: null,
        },
        series: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        refreshData(params) {
            this.$emit("refresh", params)
        },
    },
}
